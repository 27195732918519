import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = [ "icon" ]
  static classes = [ "open" ]

  connect() {
    this.#setClasses()
  }

  toggle(event) {
    this.#setClasses()
  }

  #setClasses() {
    if (this.element.open) this.iconTarget.classList.add(...this.openClasses)
    if (!this.element.open) this.iconTarget.classList.remove(...this.openClasses)
  }
}
