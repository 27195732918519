import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  connect() {
    this.updateExpanded()
    this.update()
  }

  update() {
    if (!this.element.open) return
    this.updateExpanded()

    if (this.hasLinkTarget) {
      this.linkTarget.click()
    }
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return

    this.close()
  }

  closeOnFocusOutside({ target }) {
    if (!this.element.open) return
    if (this.element.contains(target)) return
    if (target.matches("main")) return

    this.close()
  }

  close() {
    this.element.open = false
  }

  // private

  updateExpanded() {
    this.summaryElement?.setAttribute("aria-expanded", this.element.open)
  }

  get summaryElement() {
    return this.element.querySelector("summary")
  }
}
