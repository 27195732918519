
        import * as module0 from 'controllers/accordion_controller.js';import * as module1 from 'controllers/compound_interest_controller.js';import * as module2 from 'controllers/currency_input_controller.js';import * as module3 from 'controllers/popup_controller.js';import * as module4 from 'controllers/scroll_animation_controller.js';import * as module5 from 'controllers/ticker_controller.js'
        const modules = {
            "controllers/accordion_controller.js": module0,
            "controllers/compound_interest_controller.js": module1,
            "controllers/currency_input_controller.js": module2,
            "controllers/popup_controller.js": module3,
            "controllers/scroll_animation_controller.js": module4,
            "controllers/ticker_controller.js": module5,
        };
        export default modules;
      